import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ddcc717 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _05cbd3f5 = () => interopDefault(import('../pages/car-tax-landing.vue' /* webpackChunkName: "pages/car-tax-landing" */))
const _233f3549 = () => interopDefault(import('../pages/checkyourname-landing.vue' /* webpackChunkName: "pages/checkyourname-landing" */))
const _4f0a4d90 = () => interopDefault(import('../pages/confidential-policy.vue' /* webpackChunkName: "pages/confidential-policy" */))
const _cf8b4eda = () => interopDefault(import('../pages/credit/index.vue' /* webpackChunkName: "pages/credit/index" */))
const _7a95a464 = () => interopDefault(import('../pages/elastic.vue' /* webpackChunkName: "pages/elastic" */))
const _1d16aac2 = () => interopDefault(import('../pages/email-landing.vue' /* webpackChunkName: "pages/email-landing" */))
const _400e9a50 = () => interopDefault(import('../pages/email-landing/gibdd.vue' /* webpackChunkName: "pages/email-landing/gibdd" */))
const _96d63e7e = () => interopDefault(import('../pages/expert.vue' /* webpackChunkName: "pages/expert" */))
const _33e33446 = () => interopDefault(import('../pages/inn-not-found.vue' /* webpackChunkName: "pages/inn-not-found" */))
const _42452cf0 = () => interopDefault(import('../pages/nalog-app-confidential-policy.vue' /* webpackChunkName: "pages/nalog-app-confidential-policy" */))
const _42928b00 = () => interopDefault(import('../pages/o-servise.vue' /* webpackChunkName: "pages/o-servise" */))
const _107eac7f = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _c3e56db0 = () => interopDefault(import('../pages/payment-article.vue' /* webpackChunkName: "pages/payment-article" */))
const _7de72748 = () => interopDefault(import('../pages/payment-fail.vue' /* webpackChunkName: "pages/payment-fail" */))
const _b5b7398c = () => interopDefault(import('../pages/payment-guarantee.vue' /* webpackChunkName: "pages/payment-guarantee" */))
const _fb793c74 = () => interopDefault(import('../pages/payment-history.vue' /* webpackChunkName: "pages/payment-history" */))
const _6c44f260 = () => interopDefault(import('../pages/payment-mobile.vue' /* webpackChunkName: "pages/payment-mobile" */))
const _76e67aa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _7ebd8397 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _72c92716 = () => interopDefault(import('../pages/saved-cards.vue' /* webpackChunkName: "pages/saved-cards" */))
const _1366216c = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _91f32c6c = () => interopDefault(import('../pages/widget-payment-success.vue' /* webpackChunkName: "pages/widget-payment-success" */))
const _24ef33e4 = () => interopDefault(import('../pages/credit/questionnaire.vue' /* webpackChunkName: "pages/credit/questionnaire" */))
const _3099d6e7 = () => interopDefault(import('../pages/credit/sent.vue' /* webpackChunkName: "pages/credit/sent" */))
const _4f67aabf = () => interopDefault(import('../pages/widgets_v2/fns.vue' /* webpackChunkName: "pages/widgets_v2/fns" */))
const _ace39ff4 = () => interopDefault(import('../pages/widgets_v2/fssp.vue' /* webpackChunkName: "pages/widgets_v2/fssp" */))
const _78c4a8d4 = () => interopDefault(import('../pages/widgets_v2/gibdd.vue' /* webpackChunkName: "pages/widgets_v2/gibdd" */))
const _0000a979 = () => interopDefault(import('../pages/widgets/check-your-name.vue' /* webpackChunkName: "pages/widgets/check-your-name" */))
const _1926e0c4 = () => interopDefault(import('../pages/widgets/fns.vue' /* webpackChunkName: "pages/widgets/fns" */))
const _17b5baa1 = () => interopDefault(import('../pages/widgets/fssp.vue' /* webpackChunkName: "pages/widgets/fssp" */))
const _60e31cce = () => interopDefault(import('../pages/widgets/gibdd.vue' /* webpackChunkName: "pages/widgets/gibdd" */))
const _911e6f68 = () => interopDefault(import('../pages/widgets/main-menu.vue' /* webpackChunkName: "pages/widgets/main-menu" */))
const _01debcea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0b2293ce = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _2c3327b2 = () => interopDefault(import('../pages/index/fns.vue' /* webpackChunkName: "pages/index/fns" */))
const _5ee79b9e = () => interopDefault(import('../pages/index/fssp.vue' /* webpackChunkName: "pages/index/fssp" */))
const _6e98b03c = () => interopDefault(import('../pages/index/gibdd.vue' /* webpackChunkName: "pages/index/gibdd" */))
const _5a728c5c = () => interopDefault(import('../pages/articles/_alias.vue' /* webpackChunkName: "pages/articles/_alias" */))
const _3766f6ed = () => interopDefault(import('../pages/search-form/_id.vue' /* webpackChunkName: "pages/search-form/_id" */))
const _4021540c = () => interopDefault(import('../pages/search/_type/index.vue' /* webpackChunkName: "pages/search/_type/index" */))
const _ceaac300 = () => interopDefault(import('~/pages/index' /* webpackChunkName: "" */))
const _57d8121e = () => interopDefault(import('../node_modules/@pp-frontend/payment-success-module/pages/payment-success-page' /* webpackChunkName: "" */))
const _58b04a36 = () => interopDefault(import('../node_modules/@pp-frontend/search-module/pages/subscribe/index' /* webpackChunkName: "" */))
const _3877b402 = () => interopDefault(import('~/pages/unsubscribe' /* webpackChunkName: "" */))

const _762e098c = () => interopDefault(import('~/pages/reviews/index' /* webpackChunkName: "" */))
const _f8150f50 = () => interopDefault(import('../node_modules/@pp-frontend/shared-packages/modules/auto-payment/pages/unsubscribe.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles",
    component: _3ddcc717,
    name: "articles"
  }, {
    path: "/car-tax-landing",
    component: _05cbd3f5,
    name: "car-tax-landing"
  }, {
    path: "/checkyourname/:id",
    component: _233f3549,
    name: "checkyourname-landing"
  }, {
    path: "/confidential-policy",
    component: _4f0a4d90,
    name: "confidential-policy"
  }, {
    path: "/credit",
    component: _cf8b4eda,
    name: "credit"
  }, {
    path: "/elastic",
    component: _7a95a464,
    name: "elastic"
  }, {
    path: "/email-landing",
    component: _1d16aac2,
    name: "email-landing",
    children: [{
      path: "gibdd",
      component: _400e9a50,
      name: "email-landing-gibdd"
    }]
  }, {
    path: "/expert",
    component: _96d63e7e,
    name: "expert"
  }, {
    path: "/inn-not-found",
    component: _33e33446,
    name: "inn-not-found"
  }, {
    path: "/nalog-app-confidential-policy",
    component: _42452cf0,
    name: "nalog-app-confidential-policy"
  }, {
    path: "/o-servise",
    component: _42928b00,
    name: "o-servise"
  }, {
    path: "/payment",
    component: _107eac7f,
    name: "payment"
  }, {
    path: "/payment-article",
    component: _c3e56db0,
    name: "payment-article"
  }, {
    path: "/payment-fail",
    component: _7de72748,
    name: "payment-fail"
  }, {
    path: "/payment-guarantee",
    component: _b5b7398c,
    name: "payment-guarantee"
  }, {
    path: "/payment-history",
    component: _fb793c74,
    name: "payment-history"
  }, {
    path: "/payment-mobile",
    component: _6c44f260,
    name: "payment-mobile"
  }, {
    path: "/profile",
    component: _76e67aa2,
    name: "profile"
  }, {
    path: "/reviews",
    component: _7ebd8397,
    name: "reviews"
  }, {
    path: "/saved-cards",
    component: _72c92716,
    name: "saved-cards"
  }, {
    path: "/unsubscribe",
    component: _1366216c,
    name: "unsubscribe"
  }, {
    path: "/widget-payment-success",
    component: _91f32c6c,
    name: "widget-payment-success"
  }, {
    path: "/credit/questionnaire",
    component: _24ef33e4,
    name: "credit-questionnaire"
  }, {
    path: "/credit/sent",
    component: _3099d6e7,
    name: "credit-sent"
  }, {
    path: "/widgets_v2/fns",
    component: _4f67aabf,
    name: "widgets_v2-fns"
  }, {
    path: "/widgets_v2/fssp",
    component: _ace39ff4,
    name: "widgets_v2-fssp"
  }, {
    path: "/widgets_v2/gibdd",
    component: _78c4a8d4,
    name: "widgets_v2-gibdd"
  }, {
    path: "/widgets/check-your-name",
    component: _0000a979,
    name: "widgets-check-your-name"
  }, {
    path: "/widgets/fns",
    component: _1926e0c4,
    name: "widgets-fns"
  }, {
    path: "/widgets/fssp",
    component: _17b5baa1,
    name: "widgets-fssp"
  }, {
    path: "/widgets/gibdd",
    component: _60e31cce,
    name: "widgets-gibdd"
  }, {
    path: "/widgets/main-menu",
    component: _911e6f68,
    name: "widgets-main-menu"
  }, {
    path: "/",
    component: _01debcea,
    children: [{
      path: "",
      component: _0b2293ce,
      name: "index"
    }, {
      path: "fns",
      component: _2c3327b2,
      name: "index-fns"
    }, {
      path: "fssp",
      component: _5ee79b9e,
      name: "index-fssp"
    }, {
      path: "gibdd",
      component: _6e98b03c,
      name: "index-gibdd"
    }]
  }, {
    path: "/articles/:alias",
    component: _5a728c5c,
    name: "articles-alias"
  }, {
    path: "/search-form/:id?",
    component: _3766f6ed,
    name: "search-form-id"
  }, {
    path: "/search/:type",
    component: _4021540c,
    name: "search-type"
  }, {
    path: "/search-form/:id",
    components: {
      default: _ceaac300
    },
    name: "search-form-old"
  }, {
    path: "/payment-success",
    component: _57d8121e,
    props: (route) => ({ project: 'gos-oplata' }),
    name: "payment-success"
  }, {
    path: "/subscribe",
    component: _58b04a36,
    props: (route) => ({ project: 'gos-oplata' }),
    name: "subscribe"
  }, {
    path: "/email/unsubscribe",
    components: {
      default: _3877b402
    }
  }, {
    path: "/:type(fssp|fns|gibdd)/landing",
    redirect: (to) => {
          return { path: `/search/${to.params.type}`, query: { document_type: 'uin', document_value: to.query.uin, auth_key: to.query.auth_key, lead_source_id: 'email', lead_source_id_ext: to.query.lead_source_id_ext } };
        },
    name: "email"
  }, {
    path: "/email/:type(fssp|fns|gibdd)-landing",
    redirect: (to) => {
          return { path: `/search/${to.params.type}`, query: { document_type: 'uin', document_value: to.query.uin, auth_key: to.query.auth_key, lead_source_id: 'email' } };
        },
    name: "email-landing"
  }, {
    path: "/reviews/:page",
    components: {
      default: _762e098c
    }
  }, {
    path: "/autopayment/unsubscribe",
    component: _f8150f50,
    name: "autopayment-unsubscribe"
  }],

  parseQuery: (query) => require('qs').parse(query),
  stringifyQuery: (query) => {
    const result = require('qs').stringify(query);
    return result ? '?' + result : '';
  },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
